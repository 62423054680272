/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fortawesome/fontawesome-free/css/all.css";
import "./src/styles/index.scss";

import AOS from "aos"
import "aos/dist/aos.css";

AOS.init({
  duration: 400,
  offset: 0,
  easing: 'ease-in-out'
});